import React from "react";
import { format } from "d3-format";
import IntlUtil from "./IntlUtil";

export const SASE_DefaultDateFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
};

export function getLocale(): string {
    // Get locale from Intl Util service
    const intlInstance = IntlUtil.getInstance();
    return intlInstance?.getLocale() ?? "en";
}

/**
 * Identify if Locale is using 12 hour or 24 hour format
 */
export function is12Hour(): boolean {
    const locale = getLocale();
    return Boolean(Intl.DateTimeFormat(locale, { hour: "numeric" }).format(0).match(/\s/));
}

export const SASE_TimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: is12Hour()
};

export const SASE_TimeWithSecondsFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: is12Hour()
};

export const SASE_TimeWithTimezoneFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: is12Hour()
};

export const SASE_TimeZoneNameFormatOptions = {
    timeZoneName: "short"
};

export const SASE_TimeStyleFormatOptions = {
    timeStyle: "short"
};

export const SASE_Year_FormatOptions = {
    year: "numeric"
};

export const SASE_Month_FormatOptions = {
    month: "short"
};
export const SASE_Month_Num_FormatOptions = {
    month: "2-digit"
};

export const SASE_Day_FormatOptions = {
    day: "2-digit"
};

export function formatIntegersWithD3Fn(n: number| { valueOf(): number }):string {
    // length of the number without trailing zeroes
    const length = n.toString().replace(/0+$/, "").length;
    if(length < 3) {
        return format(`.${length}s`)(n);
    }

    return format(".3s")(n);
}

export function formatNumberWithD3Fn(specifier: string, n: number| { valueOf(): number }):string {
    return format(specifier)(n);
}