
import { DataSecuritySubscriptionDetailsData } from "../datasecurity-server/types";
import { OperationalHealthSubscriptionData } from "../operationalhealth-server/types";
import { NetsecUITrend, PercentageIncDecType } from "../summary-server/types";

export enum TimeRange {
    HOURS24 = "HOURS24",
    HOURS1 = "HOURS1",
    HOURS3 = "HOURS3",
    DAYS7 = "DAYS7",
    DAYS30 = "DAYS30",
}

// TODO - update when known
export type ThreatError = any;

export type Source = {
    // Capitalized since these fields are used in the D3 code
    NAME: string;
    COUNT: number;
    VALUE: number;
    PARENT?: string;
    DUMMY_CHILD: boolean;
};

export enum ChartLinkType {
    SOURCE_SUBSCRIPTION_LINK = "SOURCE_SUBSCRIPTION_LINK",
    SOURCE_SUBTYPE_SUBSCRIPTION_LINK = "SOURCE_SUBTYPE_SUBSCRIPTION_LINK",
    SUBSCRIPTION_APPTYPE_LINK = "SUBSCRIPTION_APPTYPE_LINK",
    SUBSCRIPTION_SUB_APPTYPE_LINK = "SUBSCRIPTION_SUB_APPTYPE_LINK"
}

export type Link = {
    ID: string;
    SOURCE: string;
    TARGET: string;
    COLOR: string;
    VALUE: number;
    RELATED_NODE_NAMES: string[];
    TYPE: ChartLinkType;
};

export type LicenseCounts = {
    active: number;
    expired: number;
    noLicense: number;
    hasNGFWLicense: boolean;
    hasPALicense: boolean;
    displayLicenseActive?: boolean;
}

export type ThreatSubscriptionData = {
    BLOCKED: number;
    ALLOWED: number;
    BASE_LICENSE_COUNTS: LicenseCounts;
    ADVANCED_LICENSE_COUNTS: LicenseCounts;
    TOTAL_LICENSE_COUNTS: LicenseCounts;
}

export type SubscriptionLicenseDetails = {
    ademLicenseForUserExperience?: boolean,
    ademLicenseForAdemUserInfo?: boolean,
}

export type Subscription = {
    NAME: string;
    VALUE: number;
    ENABLED: boolean;
    LICENSED?: boolean;
    NOT_APPLICABLE?: boolean;
    license?: SubscriptionLicenseDetails;
    LICENSE_EXPIRED?: boolean;
    NO_PERMISSION?: boolean;
    DETAILS: ThreatSubscriptionData | OperationalHealthSubscriptionData | DataSecuritySubscriptionDetailsData;
};

export type SourceSubType = "all" | "base" | "advanced";
export type BlockedAllowedThreats = {
    NAME: string;
    VALUE: number;
    BLOCKED: number;
    ALLOWED: number;
};
export type ThreatsPerSubscriptionType = Record<SourceSubType, BlockedAllowedThreats>;

export type AppType = {
    NAME: string;
    COUNT: number;
    VALUE: number;
};

export type GenAIFilter = {
    NAME: string;
    COUNT?: number;
    VALUE: boolean;
};
// unsanctioned, sanctioned, tolerated
export type AppSubType = {
    NAME: string;
    COUNT: number;
    VALUE: number;
}

export type App = {
    APPLICATION: string;
    APP: string;
    VALUE: number;
};

export type Action = {
    ACTION: string;
    SECURITY_SERVICE_TYPE: string;
    SEVERITY: string;
};

export type RawTimeSeries = {
    "event_time": number,
    "histogram_time": number,
    "source_type": string,
    "security_service_type": string,
    "application_type": string,
    "platform_type": string,
    "blocked_count": number,
    "allowed_count": number,
    "source_type_label": string,
    "security_service_type_label": string,
    "application_type_label": string,
    "platform_type_label": string
};

export type TimeSeries = {
    ALLOWED: Array<Record<string, any>>;
    BLOCKED: Array<Record<string, any>>;
    TOTAL_ALLOWED: number;
    TOTAL_BLOCKED: number;
    TOTAL_THREATS: number;
    TOTAL_ALLOWED_TREND: Record<any, any>;
    TOTAL_BLOCKED_TREND: Record<any, any>;
    TOTAL_TREND: Record<any, any>;
};

export type RawSource = {
    application_type: string;
    entity_count: number;
    parent: string;
    platform_type: string;
    security_service_type: string;
    source_type: string;
    source_type_label: string;
    type: string;
};

export type RawLink = {
    allowed_count: number;
    blocked_count: number;
    application_type: string;
    application_type_label: string;
    platform_type: string;
    platform_type_label: string;
    security_service_sub_type: string;
    security_service_type: string;
    security_service_type_label: string;
    severity: "Medium" | "High" | "Low";
    source_sub_type: string;
    source_sub_type_label: string;
    source_type: string;
    source_type_label: string;
    threat_category: string;
};

export type PreviousCurrentRawLink = {
    data: RawLink[];
}

export type RawSingleApp = {
    application_name: string;
    threat_count: number;
};

export type RawApp = {
    application_count: number;
    application_type: string;
    application_type_label: string;
    platform_type: string;
    security_service_type: string;
    source_type: string;
    top_applications: RawSingleApp[];
};

export type RawSubscription = {
    active: number;
    expired: number;
    no_license: number;
    security_service_sub_type: string;
    security_service_type: string;
    security_service_type_label: string;
};

export type ThreatRawAppDetails = {
    application_name: string;
    application_type: string;
    application_type_label: string;
    platform_type: string;
    platform_type_label: string;
    security_service_type: string;
    security_service_type_label: string;
    severity: string;
    source_type: string;
    source_type_label: string;
    total_threat_count: number;
};

export type ThreatRawLinkDetails = any;


export enum NetsecUIDashboardType {
    THREATS = "THREATS",
    SUMMARY = "SUMMARY",
    DATA_SECURITY = "DATA_SECURITY",
    OPERATIONAL_HEALTH = "OPERATIONAL_HEALTH"
}

export type SubscriptionDetails = {
    title: string;
    description: string;
    peerDescription: string;
    buttonText: string;
    imageSvg: string;
    imageSvgLight?: string;
    actionItem?: string;
    learnMoreLink: string;
    configureLink: string;
    enableLink: string;
};

export type NetsecUIFilter = {
    source?: Source;
    subscription?: Subscription;
    appType?: AppType;
    appSubType?: AppSubType;
    // TODO Add more fields if needed
};

export enum Severity {
    critical,
    high,
    medium,
    low,
}

export type SeverityThreatInfo = {
    severity: Severity;
    numThreats: number;
    allowedThreatCount: number;
    blockedThreatCount: number;
};

export type SelectedThreatAppDetails = {
    app: App;
    details: {
        threats: SeverityThreatInfo[];
        totalThreats: number;
    };
};


export type SelectedThreatLinkDetails = {
    link: Link;
    details: {
        threats: SeverityThreatInfo[];
        totalThreats: number;
    };
};

export type WildfireRawRow = {
    event_time: number;
    histogram_time: number;
    source_type: string;
    security_service_type: string;
    application_type: string;
    platform_type: string;
    wildfire_files_submitted_count: number;
    wildfire_malicious_verdict_count: number;
    source_type_label: string;
    security_service_type_label?: string;
    application_type_label?: string;
    platform_type_label?: string;
}

export type UrlFilteringRawRow = {
    event_time: number;
    histogram_time: number;
    source_type: string;
    security_service_type: string;
    application_type: string;
    platform_type: string;
    total_count: number;
    malicious_count: number;
    source_type_label: string;
    security_service_type_label?: string;
    application_type_label?: string;
    platform_type_label?: string;
}

export type ThreatPreventionRawRow = {
    event_time: number;
    histogram_time: number;
    source_type: string;
    security_service_type: string;
    application_type: string;
    platform_type: string;
    total_count: number;
    allowed_count: number;
    blocked_count: number;
    source_type_label: string;
    security_service_type_label?: string;
    application_type_label?: string;
    platform_type_label?: string;
}

export type DNSSecurityRawRow = UrlFilteringRawRow;

export type ThreatSubscriptionDrilldownTimeSeries = {
    totalCountsTimeSeries: NetsecUITrend,
    maliciousCountsTimeSeries: NetsecUITrend,
    percentageIncreaseTotal: PercentageIncDecType;
    percentageIncreaseMalicious: PercentageIncDecType;
}

export type ThreatSDWANDevices = {
    NAME?: string;
    VALUE?: string;
    tenant_id?: number,
    sub_tenant_id?: number,
    sdwan_site_count?: number
}

export type ThreatRawSDWANDevices = {
    sdwan_site_count?: number
}
