import { useState } from "react";


const useRecordDuration = (id: string, enableRecording: boolean) => {
    const [startTime, setStartTime] = useState<number | undefined>();
    
    const recordStartTime = () => {
        if(enableRecording) {
            setStartTime(Date.now());
        }
    };

    const logDuration = (comment: string) => {
        if(enableRecording && startTime) {
            console.log(`Duration to load ${id}`, `${Date.now() - startTime} ms`, comment);
            setStartTime(undefined);
        }
    }
    return { recordStartTime, logDuration };
}

export default useRecordDuration;