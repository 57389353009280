import { NetsecUIDashboardType } from "../../redux/threats-server/types";
import { getAuthState } from "@sparky/framework";
import { isPrivateApp } from "../../utils";
import { DataSecurityLicenseType } from "../../redux/datasecurity-server/types";
import { cloneDeep } from "lodash";

// check if any one of the casb licenses are available only then display data sec
// casb sku is a combination of ng_casb, aperture, sspm, prisma_saas, dlp licenses
export const DSLicenseAvail = (dsLicense: DataSecurityLicenseType): boolean => {
    return dsLicense?.data?.datasecurity_status === "enabled" ||
        dsLicense?.data?.aiaccess_status === "enabled";
}

export const hasAIAccessOnlyForDS = (dsLicense: DataSecurityLicenseType): boolean => {
    return dsLicense?.data?.datasecurity_status !== "enabled" &&
        dsLicense?.data?.aiaccess_status === "enabled";
}

export const hasDsLicenseEnabled = (dsLicense: DataSecurityLicenseType): boolean => {
    return dsLicense?.data?.datasecurity_status === "enabled";
}

export const DSLicenseNoPermission = (dsLicense: DataSecurityLicenseType): boolean => {
    return dsLicense?.data?.overall_status === "NO_PERMISSION";
}

export const isCASBLicenseAvail = (): boolean => {
    return  getAuthState()?.instances?.isRunningStatus("ng_casb");
}

export const dashboardHasPermission = (dsLicense: DataSecurityLicenseType, type: string): boolean => {
    switch (type) {
    case NetsecUIDashboardType.DATA_SECURITY:
        return !DSLicenseNoPermission(dsLicense);
    default:
        return true;
    }
}

// identifies if a tenant has app tagging capability
export const isAppTaggingAvailable = (): boolean => {
    const PAInstance = getAuthState()?.instances?.find((instance) => instance.app_id === "prisma_access");
    return  getAuthState()?.instances?.isRunningStatus("prisma_access") &&
        PAInstance?.extra?.platform !== "onprem";
}

// AI access is available if ng_casb app_id or aperture app_id has entitlements with sku substring matching
// one of the sku's below
export const hasAIAccess = (): boolean => {
    if(isPrivateApp()) {
        return true;
    }
    // casb tenants
    const casbEntitlements = getAuthState()?.instances?.get("ng_casb")?.entitlements
    const casbRegexList = [/AI-ACCESS/,/CASB-X/];
    const casbSkus = casbEntitlements?.filter(entitlement => {
        return casbRegexList.some(reg => {
            return reg.test(entitlement.sku);
        });
    });
    // aperture tenants check for PA ADDON
    // PA Addon license exist when all the 4 instances listed below are available
    const apertureEntitlements = getAuthState()?.instances?.get("aperture")?.entitlements;
    let inline = false, saas = false, sspm = false, paaddon = false;
    apertureEntitlements?.map((value, i) => {
        if(apertureEntitlements[i].app_id === "aperture" && (/SAASINL/).test(apertureEntitlements[i].license_type)) {
            inline = true
        }
        if(apertureEntitlements[i].app_id === "aperture" && (/SAASAPI/).test(apertureEntitlements[i].license_type)) {
            saas = true
        }
        if(apertureEntitlements[i].app_id === "aperture" && (/SAASSSPM/).test(apertureEntitlements[i].license_type)) {
            sspm = true
        }
        if(apertureEntitlements[i].app_id === "aperture" && (/PA-ADD/).test(apertureEntitlements[i].product_type)) {
            paaddon = true
        }
    });
    const apertureSkuAvail = inline && saas && sspm && paaddon;

    return (casbSkus?.length > 0 || apertureSkuAvail) &&
        getAuthState()?.instances?.isRunningStatus("logging_service");
}
// TODO move to unified app and expose to other micro apps
/*
    Method to extract features for the given user's role from the auth object
    `subtenant` is an optional param to support feature specific handling
*/

interface IFeature {
    mode: string;
    feature: string;
    description: string;
    permission: string[];
    status: string;
}

export function extractFeatures(subtenant = ""): string[] {
    const auth = getAuthState();
    const userRoles: string[] = [];
    const userFeatures = new Map();

    /* Get all the roles for the logged in user */
    auth?.access?.forEach((a) => {
        const isPaiApp = ["prisma_access", "prisma_access_panorama", "all", ""].some((appName) => appName === a.app_id)
        if (isPaiApp) {
            userRoles.push(...a.roleNames)
        }
    });

    /*
        If the user has superuser access, then return the superuser features without any merge
    */
    if (userRoles.includes("superuser")) {
        const key = subtenant ? `netsec.features.${subtenant}.superuser` : "netsec.features.superuser";

        return auth?.properties?.get(key)?.data ?? [];
    }

    /* Get all the features associated with the user roles with merged permissions */
    for (const role of userRoles) {
        const key = subtenant ? `netsec.features.${subtenant}.${role}` : `netsec.features.${role}`;
        const features = auth?.properties?.get(key)?.data ?? [];

        /*
            If any role is a custom role(missing MFE feature data)
                return empty feature set to fallback to the feature API
        */
        if (features.length === 0) {
            return [];
        }

        try {
            features.forEach((feature: IFeature) => {
                const currentFeatureName = feature.feature;
                const currentFeature: IFeature = cloneDeep(feature);

                /* If feature is present from another role, merge the permissions */
                if (userFeatures.has(currentFeatureName)) {
                    const existingPermissions = userFeatures.get(currentFeatureName).permission;

                    currentFeature.permission = [...new Set([...existingPermissions, ...feature.permission])];
                }

                userFeatures.set(currentFeatureName, currentFeature);
            });
        } catch(e) {
            const errMsg = "`command-center` failed to extract features from MFE,"
                + "falling back to the feature API call";
            console.error(errMsg, e);
            return [];
        }
    }

    return Array.from(userFeatures.values());
}

export const hasPAInstance = (): boolean => {
    return getAuthState()?.instances?.has("prisma_access");
}
