import { getFrameworkVars, getState } from "@sparky/framework";
import { NetsecUIDashboardType } from "../redux/threats-server/types";
import { platformTypeMap } from "./NetsecUIConstants";
import { getKeyByValue, isEmpty, isNilOrEmptyString } from "./NetsecUIUtils";
import { getBaseUrl } from "./getApiBaseUrlFromSparky";

/**
 * get threat count for given actionType
 * @param {*} data
 * @param {*} actionType
 * @returns {}
 */
export const getThreatsCountByActionType = (data, actionType) => {
    const dataCountByType = { critical: 0, high: 0, medium: 0, low: 0 };
    data.forEach((row) => {
        const { critical, high, medium, low } = row;
        dataCountByType["critical"] += critical?.[actionType] as number || 0;
        dataCountByType["high"] += high?.[actionType] as number || 0;
        dataCountByType["medium"] += medium?.[actionType] as number || 0;
        dataCountByType["low"] += low?.[actionType] as number || 0;
    });

    return dataCountByType;
};

export const getThreatsCount = (data) => {
    const dataCountByType = { critical: 0, high: 0, medium: 0, low: 0 };
    data.forEach((row) => {
        const { critical, high, medium, low } = row;
        dataCountByType["critical"] += (critical?.BLOCKED as number || 0) + (critical?.ALLOWED as number || 0) ;
        dataCountByType["high"] += (high?.BLOCKED as number || 0) + (high?.ALLOWED as number || 0);
        dataCountByType["medium"] += (medium?.BLOCKED as number || 0) + (medium?.ALLOWED as number || 0);
        dataCountByType["low"] += (low?.BLOCKED as number || 0) + (low?.ALLOWED as number || 0);
    });

    return dataCountByType;
}

export const getAppRiskColor = (score: number): string => {
    switch (score) {
    case 0:
    case 1:
    case 2:
    case 3:
        return "cc-bg-green-400";
    case 4:
    case 5:
    case 6:
    case 7:
        return "cc-bg-orange-400";
    case 8:
    case 9:
    case 10:
        return "cc-bg-red-500";
    }
};

/**
 * Since, filtered data is needed in almost all functions, having a util would reduce repetition
 * @param props
 * @returns
 */
export const getFilteredData = props => {
    const {
        data = [],
        sourceFilter,
        subscriptionFilter,
        appTypeFilter,
        selectedPlatformType,
        dashboardType
    } = props;

    if(isEmpty(data)) {
        return data;
    }
    const isSummaryChart = dashboardType === NetsecUIDashboardType.SUMMARY;
    const isOperationalHealthChart = dashboardType === NetsecUIDashboardType.OPERATIONAL_HEALTH;

    const sourceType = !isNilOrEmptyString(sourceFilter) && sourceFilter;
    let platformType =
        !isNilOrEmptyString(selectedPlatformType) &&
        selectedPlatformType !== "all" &&
        selectedPlatformType;
    const appType = !isNilOrEmptyString(appTypeFilter) && appTypeFilter;
    let subscriptionType = !isNilOrEmptyString(subscriptionFilter) && subscriptionFilter;
    
    if((isSummaryChart || isOperationalHealthChart) && subscriptionType) {
        platformType = getKeyByValue(platformTypeMap, subscriptionType?.NAME);
        subscriptionType = undefined;
    }

    let filteredData = sourceType
        ? data.filter((data) => data.source_type_label === sourceType.NAME)
        : data;
    filteredData = platformType
        ? filteredData.filter((data) => data?.platform_type?.toLowerCase() === platformType?.toLowerCase())
        : filteredData;
    filteredData = appType
        ? filteredData.filter((data) => data.application_type_label === appType.NAME)
        : filteredData;
    filteredData = subscriptionType
        ? filteredData.filter((data) => data.security_service_type_label === subscriptionType.NAME)
        : filteredData;
    return filteredData;
}

export const isPrivateApp = () => {
    const { private_site_origin } = getFrameworkVars();
    return Boolean(private_site_origin);
}

export const getDataSecurityApiBaseUrl = (sparkyState) => {
    const { saasSecurityGlobalState } = sparkyState;

    return isPrivateApp() ? getBaseUrl() : saasSecurityGlobalState?.baseUrl;
}

export const sortByPropArray = (targetArr, sortByArr, sortByProp) => {
    return targetArr?.sort(
        (a, b) =>
            sortByArr.indexOf(a[sortByProp]) - sortByArr.indexOf(b[sortByProp]),
    );
}

export const hasFeature = (featureId: string): boolean => {
    const { cosmosFeatures } = getState()?.saseIAMainState ?? {};
    
    if (!cosmosFeatures || cosmosFeatures.length === 0) {
        console.error("Command Center reporting: cosmosFeatures is not defined.");
        return;
    }
    const isFeatureEnabled = cosmosFeatures?.findIndex(feature => feature?.feature === featureId) >= 0;

    return isFeatureEnabled;
};