import Logger from "../Logger";
import LOCALES from "../i18n/locales";

function readFile(locale: string): Record<string, string> {
    try {
        return require(`@localize/command-center-ui/${locale}/messages.json`);
    } catch {
        Logger.info(`Translations for user Locale ${locale} not found.`);
        return require(`@localize/command-center-ui/${LOCALES.en}/messages.json`);
    }
}
export function buildLocales(locale: string): Record<string, string> {
    const locales = { ...(readFile(locale)?? {} ) };
    return locales;
}
