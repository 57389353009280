import {
    createIntl,
    createIntlCache,
    FormatDateOptions,
    FormatNumberOptions,
    IntlShape,
    MessageFormatElement,
} from "react-intl";
import _isNil from "lodash/isNil";
import LOCALES from "./locales";
import { getConfig } from "./i18nDefaultConfig";
export type LocalizationMessage =
| Record<string, string>
| Record<string, MessageFormatElement[]>
| undefined;
export const DEFAULT_LOCALE = LOCALES.en;
export const DEFAULT_TIME_ZONE =
Intl.DateTimeFormat().resolvedOptions().timeZone;

interface IntlConfig {
    locale: string;
    messagesPerLocale?: Record<string, Record<string, string>>;
    timeZone?: any;
}

const intlCache = createIntlCache();
class IntlUtil {
    private intlInstance: IntlShape;
    private static instance: IntlUtil;
    private locale: string = DEFAULT_LOCALE;
    private timeZone: string = DEFAULT_TIME_ZONE;
    private messages: LocalizationMessage;

    private constructor() {
        this.intlInstance = createIntl(
            getConfig(),
            intlCache
        );
    }

    public static init(props: IntlConfig): IntlUtil {
        return this.getIntl();
    }

    public matchesProps(props: IntlConfig): boolean {
        if (!props) {
            return false;
        }
        const { locale, timeZone, messagesPerLocale } = props;
        return this.locale !== locale || this.timeZone !== timeZone || this.messages !== messagesPerLocale?.[locale]? false : true;
    }

    public static getInstance(): IntlUtil {
        if (_isNil(IntlUtil.instance)) {
            IntlUtil.instance = new IntlUtil();
        }
        return IntlUtil.instance;
    }

    public static getIntl(): IntlShape {
        const thisInstance = IntlUtil.getInstance();
        if(!thisInstance) {
          console.error("getIntl: Access to Intl Provider is not available. ");
        }
        return thisInstance?.intlInstance;
    }


    public getLocale() {
        return this.locale;
    }

    public setLocale(locale: string) {
        this.locale = locale;
    }

    public getTimeZone() {
        return this.timeZone;
    }

    public getMessages() {
        return this.messages;
    }

    public formatDate(value: number, config?: FormatDateOptions) {
        return this.intlInstance.formatDate(value, config);
    }

    public formatTime(value: number, config?: FormatDateOptions) {
        return this.intlInstance.formatTime(value, config);
    }

    public formatNumber(value: number, config?: FormatNumberOptions) {
        return this.intlInstance.formatNumber(value, config);
    }

}
export default IntlUtil;
