import { getFrameworkVar, getState } from "@sparky/framework";

const regionMap = {
    US: new Set(["america"]),
    EU: new Set(["europe"]),
    APAC: new Set(["singapore"]),
    UK: new Set(["united kingdom"]),
    FED: new Set(["fed"]),
    GOV: new Set(["gov"]),
    IN: new Set(["india"]),
    AU: new Set(["australia"]),
    JP: new Set(["japan"])
};

const MicroAppRegion = {
    US: "US",
    EU: "EU",
    UK: "UK",
    APAC: "APAC",
    FED: "FED",
    GOV: "GOV",
    IN: "IN",
    AU: "AU",
    JP: "JP"
};

const baseUrls = {
    US: {
        production: "https://sase-saas-api.aperture.paloaltonetworks.com/inline",
        staging: "https://sase-saas-api.staging3.cirrotester.com/inline"
    },
    FED: {
        production: "https://sase-saas-api.gov.saasprod.paloaltonetworks.com/inline",
        staging: null
    },
    GOV: {
        production: "https://sase-saas-api.saas.pubsec-cloud.paloaltonetworks.com/inline",
        staging: "https://sase-saas-api.devfedm1.prisma-saas.paloaltonetworks.com/inline"
    },
    APAC: {
        production: "https://sase-saas-api.aperture-apac.paloaltonetworks.com/inline",
        staging: null
    },
    EU: {
        production: "https://sase-saas-api.aperture-eu.paloaltonetworks.com/inline",
        staging: null
    },
    UK: {
        production: "https://sase-saas-api.uk2.prisma-saas.paloaltonetworks.com/inline",
        staging: null
    },
    IN: {
        production: "https://sase-saas-api.in1.prisma-saas.paloaltonetworks.com/inline",
        staging: null
    },
    AU: {
        production: "https://sase-saas-api.au1.prisma-saas.paloaltonetworks.com/inline",
        staging: null
    },
    JP: {
        production: "https://sase-saas-api.jp1.prisma-saas.paloaltonetworks.com/inline",
        staging: null
    }
};

export const getBaseUrl = (): string => {
    const { saseIaGlobalState } = getState();
    let baseUrlProd = baseUrls.US.production;
    let baseUrlStg = baseUrls.US.staging;

    if (saseIaGlobalState && saseIaGlobalState.subtenantList && saseIaGlobalState.subtenantList.length) {
        const cdlRegion = saseIaGlobalState.subtenantList[0].cdl_region;

        const microAppRegion =
            Object.values(MicroAppRegion).find((region) => {
                return regionMap[region].has(cdlRegion.toLowerCase() ?? "");
            }) ?? MicroAppRegion.US;

        baseUrlProd = baseUrls[microAppRegion].production;
        baseUrlStg = baseUrls[microAppRegion].staging;

        return (getFrameworkVar("production") === true) ? baseUrlProd : baseUrlStg;
    }

    const defaultUrl = (getFrameworkVar("production") === true) ? baseUrlProd : baseUrlStg;
    return baseUrls["US"][defaultUrl];
};
