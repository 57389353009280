

export enum TrafficProcessedByFilter {
    ALL = "all",
    NGFW = "ngfw",
    PRISMA_ACCESS = "prisma_access"
}

export enum ApplicationFilter {
    ALL = "all",
    GEN_AI = "GenAI",
}