import { ReactNode } from "react";
import { App, AppType, Link, Source, Subscription, ThreatSDWANDevices } from "../../redux/threats-server"
import { ReactElement } from "react";


export type ChartData = {
    sources: Source[],
    links: Link[],
    subscriptions: Subscription[],
    appTypes: AppType[],
    apps: App[],
    sdwanDevices?: ThreatSDWANDevices[],
}

export type ChartMargin = {
    top: number;
    bottom: number;
    right: number;
    left: number;
}

export type SubscriptionCardProps = {
    width: number;
    height: number;
}

export type AllowedNodeOperations = "allowClick" | "allowHover";

export type ChartOptions = {
    chartName: string;
    width: number;
    height: number;
    margin: ChartMargin;
    circleRadius: number;
    getSourceId: (s) => string;
    getSubscriptionId: (s) => string;
    getAppTypeId: (s) => string;
    getAppId: (s) => string;
    getLinkId: (s) => string;
    minSourceRadius: number;
    maxSourceRadius: number;
    sourceFadeRadiusFactor: number;
    sourceInnerRadiusFactor: number;
    sourceLeafNodeRadius: number;
    subscriptionMargin: number;
    subscriptionRadius: number;
    subscriptionDashedLineWidth: number;
    subscriptionCardProperties: SubscriptionCardProps;
    formatNodeCount: (v) => string;
    formatNodeSubText: (v) => string;
    topNAppScale: number[];
    configureTransition: (transition) => void;
    appTypeRadius: number;
    appNameSpacing: number;
    linkPadding: number;
    linkGapPadding: number;
    linkWidthDomain: number[];
    subscriptionNameFontSize: string;
    subscriptionPos: (props) => number[];
    nodeActionMarginForSubscription: (
        subscriptionCardProperties: SubscriptionCardProps,
        subscriptionRadius: number) => number;
    subscriptionYTopOffset: (subscriptions: Subscription[]) => number;
    subscriptionYBottomOffset: (subscriptions: Subscription[]) => number;
    createSubscriptionSubText: (d, chartId, options) => void;
    showSubscriptionCard: ({ subscriptionCard1, subscriptionCard2, subscriptionCard3, subscriptionContainer }, { subscriptionPos }) => void;
    mouseEnterAppEventCallback: (props) => void;
    mouseEnterLinkEventCallback: (props) => void;
    onNodeClickEventCallback: (props) => void;
    linkColors: string[];
    shouldRenderSdwanNode: (platformType) => boolean;
    renderChartLegend: () => void;
    createSubscriptionCardContents:
    (subscriptions: Subscription[], subscriptionFilter: Subscription, props) => ReactElement;
    filteredSubscriptionYOffset: (options: ChartOptions) => number;
    allowedAppTypeNodeOperations: Record<AllowedNodeOperations, boolean>;
    nodeActionsPositions: { appTypeYOffset: number, nodeActionTitleYPos: number, nodeActionSwitchToYPos: number };
    showFunnelFilter: boolean;
    showPlatformTypeFilter: boolean;
    allowSourceDrilldown: boolean;
    renderAppScore: boolean;
    showPagination: boolean;
    showSubscriptionInfoIcon: boolean;
    allowClickForDatum: (datum) => boolean;
    genAIFilterCard: {
        yPos: number,
    },
    allowAppSubTypeDrilldown: (datum) => boolean;
}

export type FooterCardProps = {
    name: string;
    suggestedActionsPath?: string;
    suggestedActionsCallback?: () => {}
    children: ReactNode[],
    actionsLabel: ReactNode,
    loading: boolean,
    error: string,
    hideLink: boolean;
    linkToOpenInNewTab?: string;
}

export type NgfwFilterCustomOption = {start: string, end: string};
export type NgfwFilterOption = {label: string | NgfwFilterCustomOption, value: string | NgfwFilterCustomOption};

export type ActivityInsightsTimeRange = "30d" | "7d" | "24h" | "1h" | "3h";
export type DLPTimeRange = "past_30_days" | "past_7_days" | "past_24_hours" | "past_60_minutes" | "past_3_hours";

// NODE Types - These constants are used to identify which node type an event is
export const SOURCE_NODE_TYPE = "source-node";
export const SUBSCRIPTION_NODE_TYPE = "subscription-node";
export const APP_TYPE_NODE_TYPE = "app-type-node";
export const APP_SUB_TYPE_NODE_TYPE = "app-sub-type-node";
export const APP_NODE_TYPE = "app-node";
export const LINK_NODE_TYPE = "link-node";
export const NODE_CLICK_EVENT = "node-click";
export const NODE_BACK_BUTTON_CLICK_EVENT = "node-backbutton-click";
export const SELECT_SOURCE_EVENT = "select-source";
export const SELECT_SUBSCRIPTION_EVENT = "select-subscription";
export const SELECT_APP_TYPE_EVENT = "select-app-type";
export const SELECT_APP_EVENT = "select-app-name";
export const RERENDER_EVENT = "rerender";
export const BACKGROUND_CLICK_EVENT = "background-click";
export const MOUSE_ENTER_EVENT = "mouse-enter";
export const MOUSE_MOVE_EVENT = "mouse-move";
export const MOUSE_LEAVE_EVENT = "mouse-leave";