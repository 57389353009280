import Logger from "../Logger";
import isEmpty from "lodash/isEmpty";
import { buildLocales } from "./buildLocales";
import { MissingTranslationError } from "react-intl";

interface I18nConfigItf {
    locale: string;
    messages: Record<string, string>;
    onError(err: MissingTranslationError): void;
}

class I18nConfig {
    private locale: string;
    private messages: Record<string, string>;
    private static instance: I18nConfig;
    private constructor() {
        /** */
    }
    private onErrorHandler(err) {
        Logger.info(`Translation not available for key - ${err?.descriptor?.id ?? err}`);
    }
    public static getInstance(): I18nConfig {
        if (this.instance === undefined || this.instance === null) {
            this.instance = new I18nConfig();
        }
        return this.instance;
    }
    public getConfig(): I18nConfigItf {
        return { locale: this.locale, onError: this.onErrorHandler, messages: this.messages };
    }

    public buildConfig(locale: string): I18nConfigItf {
        if (this.locale === undefined || this.locale === null || isEmpty(this.messages)) {
            this.locale = locale;
            this.messages = buildLocales( locale );
        }
        return this.getConfig();
    }
}

export function buildConfig(locale: string): I18nConfigItf {
    return I18nConfig.getInstance().buildConfig(locale);
}

export function getConfig() {
    return I18nConfig.getInstance().getConfig();
}
