import { State } from "./threats-server/reducer";
import { State as SummaryState } from "./summary-server/reducer";
import { State as DataSecurityState } from "./datasecurity-server/reducer";
import { State as OperationalHealthState } from "./operationalhealth-server/reducer";

import moment from "moment";
import { ReduxData, ReduxMap } from "./types";
import { NgfwFilterOption } from "../components/NetsecUIChart/NetsecUIChartTypes";
import { TimeRange } from "./threats-server/types";

export interface ReduxAction<T = any, P = any> {
    reduxDataKey?: string; // identifies the redux data associates with this action
    type: string;
    params?: P;
    data?: T;
    error?: any;
    requestTime?: number;
    startTime?: any;
  }

export const fetchRequest = <Request>(
    type: string,
    params: Request,
    requestTime: number,
    reduxDataKey: string | undefined
): ReduxAction => ({
        reduxDataKey,
        type,
        params,
        requestTime,
    });

export  const fetchSuccess = <Request, Response>(
    type: string,
    params: Request,
    data: Response,
    requestTime: number,
    reduxDataKey: string | undefined
): ReduxAction<Response> => ({
        reduxDataKey,
        type,
        params,
        data,
        requestTime,
    });

export const fetchFailure = <Request>(
    type: string,
    params: Request,
    error: unknown,
    requestTime: number,
    reduxDataKey: string | undefined
): ReduxAction => ({
        reduxDataKey,
        type,
        params,
        error,
        requestTime,
    });

export const expiredRequest = (reduxData: ReduxData, requestTime: number | undefined): boolean =>
    !!requestTime &&
  !!reduxData &&
  !!reduxData.timestamp &&
  reduxData.timestamp > requestTime &&
  reduxData.loading === false && reduxData.data !== undefined;

// This is reused across all dashboard stores
export const handleMapRequest = (state: any, stateKey: keyof State|keyof SummaryState|keyof DataSecurityState|keyof OperationalHealthState| keyof AiState, action: ReduxAction) => {
    const key: any = action.reduxDataKey;
    const subState = state?.[stateKey];
    const keyState = (subState as ReduxMap)?.[key];
    // no need to update state if there is already data from a newer request
    if (expiredRequest(keyState, action.requestTime)) {
        return state;
    }
    return {
        ...state,
        [stateKey]: {
            ...state[stateKey],
            [key]: {
                loading: true,
                timestamp: action.requestTime,
                data: undefined,
            },
        },
    };
};

// This is reused across all dashboard stores
export const handleMapSuccess = (state: any, stateKey: any, action: ReduxAction) => {
    const key: any = action.reduxDataKey;
    const subState = state?.[stateKey];
    const keyState = (subState as ReduxMap)?.[key];
    // no need to update state if there is already data from a newer request
    if (expiredRequest(keyState, action.requestTime)) {
        return state;
    }
    return {
        ...state,
        [stateKey]: {
            ...state[stateKey],
            [key]: {
                loading: false,
                timestamp: action.requestTime,
                data: action.data,
            },
        },
    };
};

// This is reused across all dashboard stores
export const handleMapFailure = (
    state: any,
    stateKey: keyof State|keyof SummaryState|keyof DataSecurityState|keyof OperationalHealthState| keyof AiState,
    action: ReduxAction
) => {
    const key: any = action.reduxDataKey;
    const subState = state?.[stateKey];
    const keyState = (subState as ReduxMap)?.[key];
    // no need to update state if there is already data from a newer request
    if (expiredRequest(keyState, action.requestTime)) {
        return state;
    }
    return {
        ...state,
        [stateKey]: {
            ...state[stateKey],
            [key]: {
                loading: false,
                timestamp: action.requestTime,
                error: action.error instanceof Error? action.error?.message : action.error,
            },
        },
    };
};

export const convertTimeRangeToEpoch = (timeRange: TimeRange) => {
    const currentTime = moment();
    const currentTimeString = currentTime.valueOf();
    switch(timeRange) {
    case TimeRange.HOURS3:
        return [currentTime.subtract("3", "hours").valueOf(), currentTimeString];
    case TimeRange.HOURS1:
        return [currentTime.subtract("1", "hours").valueOf(), currentTimeString];
    case TimeRange.HOURS24:
        return [currentTime.subtract("24", "hours").valueOf(), currentTimeString];
    case TimeRange.DAYS7:
        return [currentTime.subtract("7", "days").valueOf(), currentTimeString];
    case TimeRange.DAYS30:
        return [currentTime.subtract("30", "days").valueOf(), currentTimeString];
    }
    return [currentTime.subtract("30", "days").valueOf(), currentTimeString];
}

export const constructCustomTimerangeOption = (subtractorFunction: (m: moment.Moment) => moment.Moment): NgfwFilterOption => {
    const now = moment();
    const end = now.toISOString();
    const start = subtractorFunction(now).toISOString();
    return {
        label: { start, end },
        value: { start, end }
    }
}
