/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from "./file-code-solid.svg"
// import { ReactComponent as icon } from "./file-code-solid.svg"
import {
    CommandCenterIcon,
    HomeIcon
} from "@panwds/icons";
import pkg from "../package.json";
import { hasAIAccess } from "./netsec-ui/components/NetsecUIChart/NetsecUILicenseUtil";
const App = async () => await import("./AppViewer");
const AiAccess = async () => await import("./ai-access/AiAccess");

export default async function init({ sparkyFramework, vars }) {
    console.info(
        `%c${pkg.name}: ${pkg.version}`,
        "font-size: 14px; color: white; background-color: black; border-radius: 8px; padding: 10px 15px;",
    );

    const netsecUIRoutes = [
        {
            key: "sase-home",
            title: "Command Center",
            path: "/aiops-free/home",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            icon: HomeIcon
        },
        {
            key: "sase-home",
            title: "Command Center",
            path: "/aiops-free/home/summary",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            icon: HomeIcon,
            hidden: true
        }, {
            key: "home-threats",
            title: "Command Center - Threats",
            path: "/aiops-free/home/threats",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            hidden: true
        }, {
            key: "home-operational_health",
            title: "Command Center - Operational Health",
            path: "/aiops-free/home/operational_health",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            hidden: true
        }, {
            key: "home-data_security",
            title: "Command Center - Data Security",
            path: "/aiops-free/home/data_security",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            hidden: true
        }
    ]
    const frameworkVars = sparkyFramework.getFrameworkVars() ?? {};
    const generateChildRoutesWithProps = (routes) => (
        routes.map((route) => ({
            ...route,
            mapStateToProps: (props) => ({
                ...props,
                routes: [route],
                vars,
                frameworkVars,
            }),
        }))
    );

    // to find out if tenant region is supported, it should
    // not be part of the blocked regions list
    const isTenantRegionBlocked = () => {
        // since, this issue is going to happen in ngfw tenants only, check a ngfw instance for
        // region because it might be possible that prisma_access instande is not at all available
        const region = sparkyFramework.getAuthState()?.instances?.get("strata_insights")?.region;
        // pai API"s are not supported in following regions
        const unSupportedRegions = ["pl", "it", "es"];

        return region ? unSupportedRegions.includes(region) : false;
    }

    const { private_site_origin } = frameworkVars;
    const privateApp = Boolean(private_site_origin);

    return function tsgLoad() {
        const auth = sparkyFramework.getAuthState();
        const isADEMTier1Role = auth?.access?.hasRole("adem_tier_1_support");
        // enabling netsec-ui for all tenants per QA request. Must be discussed before going for production
        // display netsec-ui button only when CDL is enabled
        const hasNetsecUI = () => privateApp ? true :
            !isTenantRegionBlocked() &&
            sparkyFramework.getAuthState().instances?.isRunningStatus("logging_service") && !isADEMTier1Role;

        return {
            navigation: [{
                key: "home",
                title: "Command Center",
                path: "/home",
                icon: CommandCenterIcon,
                expandable: false,
                component: App,
                mergeNav: true,
                avail: hasNetsecUI,
                availFallbackRedirect: "/dashboards",
                mapStateToProps: (props) => ({
                    ...props,
                    routes: [{
                        key: "sase-home",
                        title: "Command Center",
                        icon: "menu-sase-home",
                        path: "/home",
                        noRBACheck: true,
                        menuLink: "HOME",
                        custom: "NetsecUI",
                    }],
                    vars,
                    frameworkVars,
                })
            },
            {
                key: "aiops4ngfw-free",
                expandable: false,
                path: "/aiops-free",
                hidden: true,
                mergeNav: true,
                noRBACheck: true,
                exact: false,
                children: [
                    {
                        key: "home",
                        title: "Command Center",
                        path: "/aiops-free/home",
                        icon: CommandCenterIcon,
                        expandable: false,
                        component: App,
                        mergeNav: true,
                        avail: hasNetsecUI,
                        navPath: "/aiops-free/home/summary",
                        availFallbackRedirect: "/aiops-free/dashboards",
                        mapStateToProps: (props) => ({
                            ...props,
                            routes: generateChildRoutesWithProps(netsecUIRoutes),
                            vars,
                            frameworkVars,
                        }),
                    }
                ]
            },
            {
                key: "insights",
                path: "/insights",
                mergeNav: true,
                children: [
                    {
                        key: "ac-home",
                        avail: hasAIAccess,
                        title: "AI Access",
                        path: "/insights/ai-access",
                        availFallbackRedirect: "/dashboards",
                    },
                    {
                        key: "ac-use-case-detail",
                        title: "Use Case Detail",
                        avail: hasAIAccess,
                        path: "/insights/ai-access/use-case",
                        hidden: true,
                        availFallbackRedirect: "/dashboards",
                    }
                ],
                component: AiAccess,
            }
            ]
        };
    };
}
